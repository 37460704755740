import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut, User } from 'firebase/auth';
import {firebaseAuth} from './firebase_config';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Loom from './pages/Loom';
import LoomOld from './pages/LoomOld';
import Documents from './pages/Documents';
import Auth from './pages/Auth';
import Credits from './pages/Credits';
import Conversations from './pages/Conversations';
import TranscriptViewer from './pages/Conversation';
import PromptTemplateManager from './pages/PromptTemplateManager';
import { Provider } from 'react-redux';
import { store } from './store';
import { Logo } from './components/header/Logo';
import { HamburgerButton } from './components/header/HamburgerButton';
import { MainNav } from './components/header/MainNav';
import { SideMenu } from './components/header/SideMenu';
import { DiscordCallback } from './pages/DiscordCallback';

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  // Set up an authentication state listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, setUser);
    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);
  return (
    <Router>
      <Provider store={store}>
      <div className="App">
        <header className={`App-header ${!user ? 'logged-out' : ''}`}>
          <HamburgerButton 
            isOpen={isSideMenuOpen} 
            onClick={() => setIsSideMenuOpen(!isSideMenuOpen)} 
          />
          <MainNav user={user} />
          <Logo />
        </header>

        <SideMenu 
          isOpen={isSideMenuOpen} 
          user={user} 
          onClose={() => setIsSideMenuOpen(false)} 
        />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/loom/:documentID" element={<Loom />} />
            <Route path="/loom_old/:documentID" element={<LoomOld />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/credits" element={<Credits />} />
            <Route path="/conversations" element={<Conversations />} />
            <Route path="/conversation/:conversationID" element={<TranscriptViewer />} />
            <Route path="/templates/:templateID?" element={<PromptTemplateManager />} />
            <Route path="/auth/discord/callback" element={<DiscordCallback/>} />
          </Routes>
        </main>
      </div>
      </Provider>
    </Router>
  );
}

export default App;
