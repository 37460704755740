import './InfoPageContent.css';

export const PageContent = ({ title, children, showBackgroundImage }: 
  { title: string, children: React.ReactNode, showBackgroundImage: boolean }) => {
    return (
      <div className="page-container" style={showBackgroundImage ? {} : {backgroundImage: 'none'}}>
        <div className="content-card">
          <h1>{title}</h1>
          {children}
        </div>
      </div>
    );
  };