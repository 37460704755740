import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { User } from 'firebase/auth';
// import './LoomOld.css';
import { Conversation, DocumentValue, LoomNodeValue, PromptTemplate, Transcription } from '../common/common_db';
import { wait, FireLoomStore }  from '../db';
import { firebaseAuth, getCurrentUser } from '../firebase_config';
import { callCompletions } from '../callables';
import PromptTemplateForm from '../components/PromptTemplateForm';
import TemplatesSidebar from '../components/TemplatesSidebar';
import {textFromTemplatedPrompt} from '../common/util';
import LoadingSpinner from '../components/LoadingSpinner';
import EditableText from '../components/EditableText';
import { ModelType, Prompt } from '../common/llm_clients';


type LoomEvents = {
  document: string;
  isOwner: boolean;
  incrementVersion: () => void;
  isGenerating: (nodeID: string) => boolean;
  isOnSelectPath: (nodeID: string) => boolean;
  generateChildren: (nodeID: string) => Promise<string[]>;
  onSelectNode: (nodeID: string) => void;
  onHoistNode: (nodeID: string) => void;
  getDocument(docID: string): Promise<DocumentValue | null>;
  updateDocument(docID: string, docVal: DocumentValue): Promise<void>;
  getNode(nodeID: string): Promise<LoomNodeValue | null>;
  getChildren(nodeID: string): Promise<string[]>;
  invalidateChildCache(nodeID: string): void;
  createNode(docID: string, parentID: string | null, text: string) : Promise<{id: string, node: LoomNodeValue}>;
  updateNode(nodeID: string, node: LoomNodeValue) : Promise<void>;
};


// PromptInput Component (only visible before submission)
function PromptInput({ onSetPrompt }: { onSetPrompt: (prompt: string) => void }) {
  const [prompt, setPrompt] = useState("");

  const handleSetPromptClick = () => {
    if (prompt) {
      onSetPrompt(prompt);
    }
  };

  return (
    <div className="prompt-input">
      <textarea
        placeholder="Write or paste your prompt here..."
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        rows={20}
        cols={80}
      />
      <br />
      <button onClick={handleSetPromptClick}>Set Prompt</button>
    </div>
  );
}


function StaticText({ text, lastText }: { text: string, lastText: string }) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text + lastText).then(() => {
    }).catch(() => {
      alert('Failed to copy text.');
    });
  };

  return (
    <div className="static-text-container">
      <button className="round-button" onClick={copyToClipboard}>Copy</button>
      <div className="static-text">
        <div className="pre-wrap-text">{text}<b>{lastText}</b></div>
      </div>
    </div>
  );
}

function LoomNodeComponent({
  nodeID,
  loomEvents,
  version,
  hoistedToSelf,
  selectedNode
} : {
  nodeID: string;
  loomEvents: LoomEvents;
  version: number;
  hoistedToSelf: string[];
  selectedNode: string;
}) {
  const [loomNode, setLoomNode] = useState<LoomNodeValue | null>(null);
  const [children, setChildren] = useState<string[] | null>(null);


  const isHoisted = hoistedToSelf.length === 0;
  const childrenTooDeep = hoistedToSelf.length >= 4;
  const isSelected = nodeID === selectedNode;
  const isOnPath = loomEvents.isOnSelectPath(nodeID);
  const isGenerating = loomEvents.isGenerating(nodeID);

  useEffect(() => {
    loomEvents.getNode(nodeID).then((node) => {
      if (node === null) {
        console.error('Failed to get node');
        return;
      }
      setLoomNode(node);
    });
  }, [version, nodeID]);

  useEffect(() => {
    loomEvents.getChildren(nodeID).then((children) => {
      setChildren(children);
    });
  }, [version, nodeID]);

  if (loomNode === null) {
    return <div>Loading...</div>;
  }

  if (!nodeID) {
    return <div>ERROR: Invalid node ID</div>;
  }

  function handleToggleExpand() {
    if (loomNode == null) {
      throw new Error('Unexpected null node');
    }
    var newLoomNode = { ...loomNode, isExpanded: !loomNode.isExpanded };
    loomEvents.updateNode(nodeID, newLoomNode);
    setLoomNode(newLoomNode);
  }

  function handleHoist() {
    if (isHoisted) {
      const go = async () => {
        var curr = nodeID;
        for (var i = 0; i < 3; ++i) {
          const node = await loomEvents.getNode(curr);
          if (node == null || node.parentID == null) {
            break;
          }
          curr = node.parentID;
        }
        loomEvents.onHoistNode(curr);
      };
      go();
    } else {
      loomEvents.onHoistNode(nodeID);
    }
  }

  function handleGenerateChildren() {
    if (isGenerating) {
      return;
    }
    loomEvents.generateChildren(nodeID).then((newChildren) => {
      loomEvents.getNode(nodeID).then((loomNode2) => {
        if (!loomNode2) {
          console.error('Failed to lookup node');
          return;
        }
        for (let i = 0; i < newChildren.length; i++) {
          loomEvents.createNode(loomEvents.document, nodeID, newChildren[i]).then(({id, node}) => {
            loomEvents.incrementVersion();
          });
        }
      });
    });
  }

  function handleSelect() {
    loomEvents.onSelectNode(nodeID);
  }

  function handleAddChild() {
    loomEvents.getNode(nodeID).then((loomNode2) => {
      if (!loomNode2) {
        console.error('Failed to lookup node');
        return;
      }
      loomEvents.createNode(loomEvents.document, nodeID, '(New child)').then(({id, node}) => {
        loomEvents.incrementVersion();
      });
    });
  }


  var dispText = loomNode.text;
  if (dispText.length > 50) {
    dispText = dispText.slice(0, 50) + '...';
  } else if (dispText.length === 0) {
    dispText = '(No text)';
  }

  function handleDelete() {
    if (!window.confirm(`Are you sure you want to delete this node: ${dispText || '<no text>'}?`)) {
      return;
    }
    loomEvents.getNode(nodeID).then((loomNode2) => {
      if (!loomNode2) {
        console.error('Failed to lookup node');
        return;
      }
      loomEvents.updateNode(nodeID, {...loomNode2, isDeleted: true}).then(() => {
        if (loomNode2.parentID != null) {
          loomEvents.invalidateChildCache(loomNode2.parentID);
        }
        loomEvents.incrementVersion();
      });
    });
  }

  return (
    <div className="loom-node">
      <div className="node-header">
        <span
          className={isSelected ? 'node-selected' : isOnPath ? 'node-on-path' : ''}
          style={{ flex: 1 }}
          onClick={handleSelect}>
          <EditableText
            value={loomNode.text}
            rows={4}
            dispValue={dispText}
            disabled={!loomEvents.isOwner}
            isPointer={true}
            trySetValue={async (newText: string) => {
              const newLoomNode = {...loomNode, text: newText};
              await loomEvents.updateNode(nodeID, newLoomNode);
              setLoomNode(newLoomNode);
              loomEvents.onSelectNode(nodeID);
            }}
          />
        </span>
        {children && children.length > 0 && (
          <span
            className="expand-toggle"
            onClick={handleToggleExpand}
            style={{ cursor: 'pointer' }}
            title={loomNode.isExpanded ? 'Collapse' : 'Expand'}
          >
            {loomNode.isExpanded ? '▼' : '▶'}
          </span>
        )}
        {loomNode?.parentID != null && (
          <span
            className="hoist-toggle"
            onClick={handleHoist}
            style={{ cursor: 'pointer' }}
            title={isHoisted ? 'Unhoist' : 'Hoist'}
          >
            {isHoisted ? '↓' : '↑'}
          </span>)}


        {loomEvents.isOwner && (isGenerating ?
          (<span className="loom-spinner"><LoadingSpinner size="tiny"/></span>)
          :
          (<span
            className="generate-icon"
            onClick={handleGenerateChildren}
            style={{ cursor: 'pointer' }}
            title="AI Completion"
          >✨</span>))}

        {loomEvents.isOwner &&
          <span className="add-child"
                onClick={handleAddChild}
                style={{cursor: 'pointer'}}
                title="Add Child"
          >➕</span>}
        {loomEvents.isOwner && loomNode.parentID != null &&
          <span
            className="delete-node"
            onClick={handleDelete}
            style={{ cursor: 'pointer' }}
            title="Delete"
          >🗑️</span>}
      </div>
      {loomNode.isExpanded && (
        children == null ?
          <div>Loading...</div>
        : childrenTooDeep ?
          <div> (too deep) </div>
        :
        <div className="node-children">
          {children.map((childID, i) => (
            <LoomNodeComponent
              nodeID={childID}
              key={i}
              loomEvents={loomEvents}
              hoistedToSelf={[...hoistedToSelf, childID]}
              selectedNode={selectedNode}
              version={version}
            />
          ))}
        </div>
      )}
    </div>
  );

}

function DocOptions({
  loomEvents,
  version
}: {
  loomEvents: LoomEvents;
  version: number;
}) {
  const [docValue, setDocValue] = useState<DocumentValue | null>(null);
  const loomStore = useMemo(() => new FireLoomStore(), []);

  var docID = loomEvents.document;

  useEffect(() => {
    getCurrentUser().then((user) => {
      loomStore.getDocument(docID).then((docValue) => {
        setDocValue(docValue);
      });
    });
  }, [docID, loomStore, version]);


  async function trySetMaxTokens(s: string): Promise<void> {
    const maxTokens = parseInt(s);

    if (isNaN(maxTokens) || maxTokens < 10 || maxTokens > 2048) {
      throw new Error('max tokens must be between 10 and 2048');
    }
    const doc = await loomEvents.getDocument(docID);
    if (doc == null) {
      throw new Error('Failed to get document');
    }
    await loomEvents.updateDocument(docID, {...doc, samplingOptions: {...doc.samplingOptions, maxTokens}});
    loomEvents.incrementVersion();
  }

  async function trySetNumSamples(s: string): Promise<void> {
    const numSamples = parseInt(s);
    if (isNaN(numSamples) || numSamples <= 0 || numSamples > 10) {
      throw new Error('num samples must be between 1 and 10');
    }
    const doc = await loomEvents.getDocument(docID);
    if (doc == null) {
      throw new Error('Failed to get document');
    }
    await loomEvents.updateDocument(docID, {...doc, samplingOptions: {...doc.samplingOptions, numSamples}});
    loomEvents.incrementVersion();
  }

  async function trySetTemperature(s: string): Promise<void> {
    const temperature = parseFloat(s);
    if (isNaN(temperature) || temperature < 0 || temperature > 2) {
      throw new Error('temperature must be between 0 and 2');
    }
    const doc = await loomEvents.getDocument(docID);
    if (doc == null) {
      throw new Error('Failed to get document');
    }
    await loomEvents.updateDocument(docID, {...doc, samplingOptions: {...doc.samplingOptions, temperature}});
    loomEvents.incrementVersion();
  }

  async function togglePublic() {
    if (docValue == null) {
      return;
    }
    const newPublic = !docValue.isPublic;
    if (newPublic) {
      if (!window.confirm("Are you sure you want to make this document public?")) {
        return;
      }
    } else {
      if (!window.confirm("Are you sure you want to make this document private?")) {
        return;
      }
    }
    const doc = await loomEvents.getDocument(docID);
    if (doc == null) {
      throw new Error('Failed to get document');
    }
    await loomEvents.updateDocument(docID, {...doc, isPublic: newPublic});
    loomEvents.incrementVersion();
  }


  return (docValue === null) ? <div>Loading...</div> : 
    !loomEvents.isOwner ? <div></div> : (
    <div className="doc-options">
      <ul>
        <li>Is public: {docValue.isPublic ? 'Yes' : 'No'}
          <button onClick={togglePublic}>{docValue.isPublic ? 'Make private' : 'Make public'}</button>
        </li>
        <li>Max tokens per sample: <EditableText cols={4} value={'' + docValue.samplingOptions.maxTokens} trySetValue={trySetMaxTokens}/></li>
        <li>Number of samples: <EditableText cols={3} value={'' + docValue.samplingOptions.numSamples} trySetValue={trySetNumSamples}/></li>
        <li>Temperature: <EditableText cols={4} value={'' + docValue.samplingOptions.temperature} trySetValue={trySetTemperature}/></li>
      </ul>
    </div>
  );
}


// TreeSidebar Component
function TreeSidebar({
  nodeID,
  selectedNode,
  loomEvents,
  version,
}: {
  nodeID: string | null;
  selectedNode: string;
  loomEvents: LoomEvents;
  version: number;
}) {
  return (
    <div className="sidebar">
      <DocOptions loomEvents={loomEvents} version={version} />
      <h3>Completion Tree</h3>
      <div className="sidebar-content">
        { nodeID === null ? <div>Loading...</div> :
          <LoomNodeComponent
            nodeID={nodeID}
            loomEvents={loomEvents}
            version={version}
            hoistedToSelf={[]}
            selectedNode={selectedNode}
          />
        }
      </div>
    </div>
  );
}

// Define the route parameter types
type RouteParams = {
  documentID: string;
}

// Main Loom Component
function LoomOld() {
  const [isLoading, setIsLoading] = useState(true);
  const [prompt, setPrompt] = useState<string | null>(null); // Store the prompt after submission
  const [conversations, setConversations] = useState<Conversation[] | null>(null);
  const [currentText, setCurrentText] = useState<string>(''); // Current text along the path
  const [lastText, setLastText] = useState<string>('');
  const [version, setVersion] = useState(0);
  const [user, setUser] = useState<User | null>(null);
  const [documentValue, setDocumentValue] = useState<DocumentValue | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<PromptTemplate | null>(null);
  const [templatesType, setTemplatesType] = useState<'personal' | 'public'>('personal');
  const [templates, setTemplates] = useState<PromptTemplate[]>([]);

  const { documentID } = useParams<RouteParams>(); // Typed `useParams` call

  const loomStore = useMemo(() => new FireLoomStore(), []);

  const isGeneratingMap = useMemo<Record<string, boolean>>(() => ({}), [documentID]);
  const isOnSelectPathMap = useMemo<Record<string, boolean>>(() => ({}), [documentID]);

  if (documentID === undefined) {
    throw new Error('Expected document name');
  }

  const isOwner = user != null && documentValue != null && user.uid == documentValue.ownerID;

  const document = documentID;

  useEffect(() => {
    getCurrentUser().then((user) => {
      loomStore.getConversations().then((conversations) => {setConversations(conversations)});
      console.log('use effect, templateType=', templatesType);
      loomStore.getTemplates(templatesType === 'public').then((templates) => {setTemplates(templates)});
      loomStore.getDocument(documentID).then((docValue) => {
        if (!docValue) {
          console.error('Document not found');
          return;
        }

        const rootID = docValue.rootNodeID;
        var rootNode = null;
        const finishInit = () => {
          setUser(user);
          setIsLoading(false);
          setDocumentValue(docValue);
        };
        if (rootID != null) {
          loomStore.getNode(rootID).then((rootNode) => {
            if (rootNode == null) {
              console.error('Failed to get root node');
              finishInit();
              return;
            }
            setPrompt(rootNode.text);
            setCurrentText('');
            setLastText(rootNode.text);
            if (docValue.selectedNodeID != null) {
              onSelectNode(docValue.selectedNodeID);
            }
            finishInit();
          }).catch((err: any) => {
            console.error('Failed to get root node: ' + err.message);
            finishInit();
          });
        } else {
          finishInit();
        }
      }).catch((err: any) => {
        console.log('Failed permission check: ' + err.message);
        setIsLoading(false);
      });
    });
  }, [documentID, loomStore, templatesType]);

  if (documentValue == null && !isLoading) {
    return <Navigate to="/auth" />;
  }

  const hoistedNodeID : string | null = documentValue?.hoistedNodeID || documentValue?.rootNodeID || null;

  const handleSetPrompt = (submittedPrompt: string) => {
    setPrompt(submittedPrompt);
    console.log('submittedPrompt', submittedPrompt);
    setCurrentText('');
    setLastText(submittedPrompt);
    loomStore.createNode(documentID, null, submittedPrompt).then(({id, node}) => {
      loomStore.getDocument(documentID).then((doc) => {
        if (doc != null) {
          const newVal = {...doc, rootNodeID: id};
          loomStore.updateDocument(documentID, newVal);
          setDocumentValue(newVal);
        }
      });
    });
  };

  function incrementVersion() {
    setVersion((version) => version + 1);
  }

  function getDocument(documentID: string): Promise<DocumentValue | null> {
    return loomStore.getDocument(documentID);
  }

  async function updateDocument(documentID: string, documentVal: DocumentValue) : Promise<void> {
    setDocumentValue(documentVal);
    if (isOwner) {
      await loomStore.updateDocument(documentID, documentVal);
    }
  }

  function getNode(nodeID: string) : Promise<LoomNodeValue | null> {
    return loomStore.getNode(nodeID);
  }

  function getChildren(nodeID: string) : Promise<string[]> {
    return loomStore.getChildren(document, nodeID);
  }

  function createNode(document: string, parentID: string | null, text: string) : Promise<{id: string, node: LoomNodeValue}> {
    return loomStore.createNode(document, parentID, text);
  }

  function updateNode(nodeID: string, node: LoomNodeValue) : Promise<void> {
    return loomStore.updateNode(nodeID, node);
  }

  async function getTextAtNode(nodeID: string | null, updateMap?: boolean): Promise<string[]> {

    const newOnSelectPathMap: Record<string, boolean> = {};
    const res = [];
    while (nodeID != null) {
      newOnSelectPathMap[nodeID] = true;
      var nodeValue = await loomStore.getNode(nodeID);
      if (nodeValue == null) {
        break;
      }
      res.push(nodeValue.text);
      nodeID = nodeValue.parentID;
    }
    if (updateMap) {
      for (var key in isOnSelectPathMap) {
        delete isOnSelectPathMap[key];
      }
      for (var key in newOnSelectPathMap) {
        isOnSelectPathMap[key] = true;
      }
    }
    res.reverse();
    return res;
  }

  function onSelectNode(nodeID: string) {
    if (documentValue != null) {
      setDocumentValue({...documentValue, selectedNodeID: nodeID});
    }
    getDocument(document).then((currDoc) => {
      if (currDoc != null) {
        const newDoc = {...currDoc, selectedNodeID: nodeID};
        updateDocument(document, newDoc);
      }
    });
    getTextAtNode(nodeID, true).then((textArr) => {
      if (textArr.length == 0) {
        setCurrentText('');
        setLastText('');
      } else {
        setCurrentText(textArr.slice(0, -1).join(''));
        setLastText(textArr[textArr.length - 1]);
      }
      incrementVersion();
    });
  }

  function onHoistNode(nodeID: string) {
    getDocument(document).then((currDoc) => {
      if (currDoc != null) {
        const newDoc = {...currDoc, hoistedNodeID: nodeID};
        updateDocument(document, newDoc);
      }
    });
  }

  async function getConvoTranscript(conversation: Conversation): Promise<Transcription | null> {
    if (!conversation.transcribed || !conversation.transcriptID) {
      throw new Error('Conversation has not been transcribed yet');
    }
    return loomStore.getTranscription(conversation.id, conversation.transcriptID)
  }

  const loomEvents: LoomEvents = {
    document,
    isOwner,
    incrementVersion,
    getDocument,
    updateDocument,
    getNode,
    getChildren,
    invalidateChildCache: (nodeID: string) => {
      loomStore.invalidateChildCache(nodeID);
    },
    createNode,
    updateNode,
    onSelectNode,
    onHoistNode,

    isGenerating: (nodeID: string) => {
      return isGeneratingMap[nodeID] || false;
    },

    isOnSelectPath: (nodeID: string) => {
      return isOnSelectPathMap[nodeID] || false;
    },

    generateChildren: async (nodeID: string) => {
      isGeneratingMap[nodeID] = true;
      incrementVersion();

      const textArr = await getTextAtNode(nodeID);
      const text = textArr.join('');

      // Set up the parameters for the API call
      const body: {model: ModelType, prompt: Prompt, max_tokens: number, n: number, temperature: number} = {
        prompt: {messages: text},
        max_tokens: documentValue?.samplingOptions?.maxTokens || 100,
        n: documentValue?.samplingOptions?.numSamples || 5,
        temperature: documentValue?.samplingOptions?.temperature || 1.0,
        model: 'meta-llama/Meta-Llama-3.1-405B-FP8',
      };
      try {
        const completions = await callCompletions(body);
        return completions;
      } catch(err: any) {
        alert(err.message);
        return [];
      } finally {
        isGeneratingMap[nodeID] = false;
        incrementVersion();
      }
    },
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="loom-container">
      <header>
        <h1 className="title">Loom</h1>
      </header>
      <div className="loom-content">
        {prompt === null ? (
          isOwner ? (
            <div className="prompt-selection-container">
              <TemplatesSidebar
                templates={templates}
                selectedTemplate={selectedTemplate}
                onSelectTemplate={setSelectedTemplate}
                templatesType={templatesType}
                onTemplateTypeChange={(v) => setTemplatesType(v)}
              />
              <div className="prompt-input-container">
                {selectedTemplate ? (
                  <PromptTemplateForm 
                    template={selectedTemplate}
                    conversations={conversations || []}
                    onSubmit={async ({template, values}) => {
                      console.log('template: ', template);
                      console.log('values: ', values);
                      const promptText = await textFromTemplatedPrompt({template, values}, getConvoTranscript);
                      console.log('promptText: ', promptText);
                      handleSetPrompt(promptText);
                    }}
                  />
                ) : (
                  <PromptInput onSetPrompt={handleSetPrompt} />
                )}
              </div>
            </div>
          ) : (<p>Document has not been initialized.</p>)
        ) : (
          // Show the static prompt and the nodes view if the prompt is submitted
          <div className="parent-container">
            <div className="loom-body">
              <StaticText text={currentText} lastText={lastText} />
            </div>
            
            <TreeSidebar
              nodeID={hoistedNodeID}
              selectedNode={documentValue?.selectedNodeID || ''}
              loomEvents={loomEvents}  // Pass loomEvents object here
              version={version}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default LoomOld;
