import React, { useState, useRef, useEffect } from 'react';
import './EditableValue.css';

interface EditableValueProps {
  value: string | number;
  onSave: (value: string) => void;
  validate?: (value: string) => boolean;
  type?: string;
  style?: React.CSSProperties;
  startEditing?: boolean;
  errorMessage?: string;
}

const EditableValue = ({ 
  value, 
  onSave,
  validate,
  type = "text",
  style = {},
  startEditing = false,
  errorMessage = "Invalid value"
}: EditableValueProps) => {
  const [isEditing, setIsEditing] = useState(startEditing);
  const [editValue, setEditValue] = useState(value.toString());
  const [error, setError] = useState<string | null>(null);
  const [errorPosition, setErrorPosition] = useState<{ top: number; left: number } | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  useEffect(() => {
    setEditValue(value.toString());
  }, [value]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (error) {
      // Calculate position when error is set
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setErrorPosition({
          top: rect.bottom + window.scrollY + 4, // 4px offset
          left: rect.left + window.scrollX
        });
      }
      
      timeout = setTimeout(() => {
        setError(null);
        setErrorPosition(null);
      }, 2000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [error]);

  const handleSave = () => {
    if (validate && !validate(editValue)) {
      setError(errorMessage);
      setEditValue(value.toString());
      setIsEditing(false);
      return;
    }
    onSave(editValue);
    setIsEditing(false);
    setError(null);
    setErrorPosition(null);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSave();
    } else if (e.key === 'Escape') {
      setEditValue(value.toString());
      setIsEditing(false);
      setError(null);
      setErrorPosition(null);
    }
  };

  return (
    <div className="editable-wrapper">
      <div 
        ref={containerRef}
        className="editable-container"
        onClick={() => !isEditing && setIsEditing(true)}
        style={style}
      >
        {isEditing ? (
          <input
            ref={inputRef}
            className="editable-input"
            value={editValue}
            onChange={e => setEditValue(e.target.value)}
            onBlur={handleSave}
            onKeyDown={handleKeyDown}
            type={type}
            style={style}
          />
        ) : (
          <span>{value}</span>
        )}
      </div>
      {error && errorPosition && (
        <div 
          className="validation-error"
          style={{
            top: errorPosition.top,
            left: errorPosition.left
          }}
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default EditableValue;