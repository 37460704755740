import {z} from 'zod';
import { LoomableModels, ModelType } from './llm_clients';
import { AnnotationParseErrorData } from './transcripts';

export type SamplingOptions = {
  maxTokens: number;
  numSamples: number;
  temperature: number;
  model: LoomableModels
};

export const defaultSamplingOptions: SamplingOptions = {
  maxTokens: 100,
  numSamples: 5,
  temperature: 1.0,
  model: 'meta-llama/Meta-Llama-3.1-405B-FP8'
};

export type DocumentValue = {
  rootNodeID: string | null;
  ownerID: string;
  creationEpochTime: number;
  name: string;
  hoistedNodeID: string | null;
  selectedNodeID: string | null;
  isPublic: boolean;
  samplingOptions: SamplingOptions;
  promptTemplate?: string;
};

export type DocumentSummary = {
  creationEpochTime: number;
  id: string;
  name: string;
  isPublic: boolean;
};

export type LoomNodeValue = {
  creationEpochTime: number;
  docID: string;
  parentID: string | null;
  text: string;
  isExpanded: boolean;
  isDeleted: boolean;
  selectedChildID: string | null;
};

export type DiscordInfoValue = {
  id: string;
  username: string;
  connectedAt: number;
}

export type UserValue = {
  name: string;
  creditsUD: number;
  isApproved: boolean;
  isResearcher?: boolean;
  discordInfo?: DiscordInfoValue
};

export type PurchaseStatus = 'pending' | 'completed' | 'failed' | 'expired' | 'confirming';

export type PurchaseValue = {
  userID: string;
  amountCents: number;
  startEpochTime: number;
  finishEpochTime: number;
  status: PurchaseStatus;
  message: string;
};

export type PromptTemplate = {
  id: string;
  ownerID: string;
  creationEpochTime: number;
  isPublic: boolean;
  name: string;
  templateText: string;
}

export type TemplatedPrompt = {
  template: PromptTemplate;
  values: TemplateValue[];
}

export type TemplateValue = string | Conversation

export type ConvoVisibility = 'public' | 'private' | 'research';

export type TranscriptionStatus = 'pending' | 'processing' | 'completed' | 'failed';

export type DiscordUserMetadata = {
  displayName: string;
  id: string;
  username: string
}

export type Participant = {
  name: string;
  userID?: string;
  platformMetadata: DiscordUserMetadata
}

export type Conversation = {
  id: string;
  name: string;
  ownerID: string;
  participants?: Participant[];
  participantIDs?: string[];
  visibility: ConvoVisibility;
  creationEpochTime: number;
  isMultiTrack: boolean;
  compositeMediaUrl: string; // for multi-track recordings, the merged audio/video file. For single track, the original media file
  speakerMediaTrackUrls?: string[]
  transcribed: boolean;
  transcriptID: string;
  transcriptionStatus: TranscriptionStatus;
  errorMessage?: string;
}

export type Transcription = {
  id: string;
  conversationID: string;
  mediaPath: string;
  transcript: TranscriptUnit[];
}

export type TimeRange = {
  start: number;
  end: number;
}

export type TranscriptUnit = TimeRange & {
  word: string;
  id: number;
  speaker: string;
  confidenceScore?: number;
}

export type TranscriptRange = TimeRange & {
  startTranscriptUnit: TranscriptUnit;
  endTranscriptUnit: TranscriptUnit;
}

export class TranscriptRangeImpl implements TranscriptRange {
  constructor(
    public startTranscriptUnit: TranscriptUnit,
    public endTranscriptUnit: TranscriptUnit
  ) {}

  get start(): number {
    return this.startTranscriptUnit.start;
  }

  get end(): number {
    return this.endTranscriptUnit.end;
  }
}

export type Rangable = TranscriptUnit | (TimeRange & {contents: Rangable[]})

export type SpeakerTurn = TimeRange & {speaker: string, contents: TranscriptUnit[]}

export type LinearTurnsTranscript = Omit<Transcription, 'transcript'> & {turns: SpeakerTurn[]}



const timeRangeSchema = z.object({
  start: z.number(),
  end: z.number(),
});

// Schema for TranscriptUnit, which extends TimeRange
const transcriptUnitSchema = timeRangeSchema.extend({
  word: z.string(),
  id: z.number(),
  speaker: z.string(),
  confidenceScore: z.number().optional(),
});

// Schema for array of TranscriptUnits
export const TranscriptArraySchema = z.array(transcriptUnitSchema);


export type TranscriptionJob = {
  fileId: string,
  status: 'pending' | 'processing' | 'completed' | 'failed',
  createdAt: number
}

//######################################################################
//
//######################################################################

const annotationErrorDataSchema = z.object({
  message: z.string(),
  transcriptTextIndex: z.number(),
  llmTextIndex: z.number(),
})

const annotationPromptResultSchema = z.object({
  transcriptChunkText: z.string(),
  llmText: z.string(),
  startUnit: transcriptUnitSchema,
  endUnit: transcriptUnitSchema,
  error: z.string().or(annotationErrorDataSchema).optional(),
  annotationIDs: z.array(z.string()),
  chunkPreviewText: z.string()
});

export const annotationPromptDebugValueSchema = z.object({
  id: z.string(),
  ownerID: z.string(),
  conversationID: z.string(),
  annotationGroupID: z.string().optional(),
  creationEpochTime: z.number(),
  userPrompt: z.string(),
  systemPrompt: z.string(),
  modelID: z.string() as z.ZodType<ModelType>,
  results: z.array(annotationPromptResultSchema)
});

export type AnnotationPromptResult = {
  transcriptChunkText: string;
  llmText: string;
  startUnit: TranscriptUnit;
  endUnit: TranscriptUnit;
  error?: string | AnnotationParseErrorData;
  annotationIDs: string[];
  chunkPreviewText: string;
}

export type AnnotationPromptDebugValue = {
  id: string;
  ownerID: string;
  conversationID: string;
  annotationGroupID?: string; // undefined if no chunks parsed successfully and no annotation group was made
  creationEpochTime: number;
  userPrompt: string;
  systemPrompt: string;
  modelID: ModelType;
  results: AnnotationPromptResult[];
}

//######################################################################
//
//######################################################################

export function documentDataToValue(data: any): DocumentValue {
  return {
    rootNodeID: data.rootNodeID || null,
    name: data.name,
    creationEpochTime: data.creationEpochTime || 0,
    ownerID: data.ownerID,
    hoistedNodeID: data.hoistedNodeID || null,
    selectedNodeID: data.selectedNodeID || null,
    isPublic: !!data.isPublic,
    samplingOptions: {
      maxTokens: data.samplingOptions?.maxTokens || defaultSamplingOptions.maxTokens,
      numSamples: data.samplingOptions?.numSamples || defaultSamplingOptions.numSamples,
      temperature: data.samplingOptions?.temperature || defaultSamplingOptions.temperature,
      model: data.samplingOptions?.model || defaultSamplingOptions.model
    }
  };
}

export function documentValueToData(value: DocumentValue): any {
  return {
    rootNodeID: value.rootNodeID,
    name: value.name,
    ownerID: value.ownerID,
    hoistedNodeID: value.hoistedNodeID,
    selectedNodeID: value.selectedNodeID,
    isPublic: value.isPublic,
    samplingOptions: value.samplingOptions
  };
}

export function loomNodeDataToValue(data: any): LoomNodeValue {
  return {
    creationEpochTime: data.creationEpochTime || 0,
    parentID: data.parentID || null,
    docID: data.docID,
    text: data.text,
    isExpanded: data.isExpanded,
    isDeleted: data.isDeleted || false,
    selectedChildID: data.selectedChildID || null
  };
}

export function loomNodeValueToData(value: LoomNodeValue): any {
  return {
    creationEpochTime: value.creationEpochTime,
    parentID: value.parentID,
    docID: value.docID,
    text: value.text,
    isExpanded: value.isExpanded,
    isDeleted: value.isDeleted,
    selectedChildID: value.selectedChildID
  };
}

export function userDataToValue(data: any): UserValue {
  return {
    name: data.name,
    creditsUD: data.creditsUD,
    isApproved: data.isApproved || false,
    isResearcher: data.isResearcher || false,
    ...(data.discordInfo ? {discordInfo: data.discordInfo} : {})
  };
}

export function userValueToData(value: UserValue): any {
  return {
    name: value.name,
    creditsUD: value.creditsUD,
    isApproved: value.isApproved,
    ...(value.isResearcher !== undefined ? {isResearcher: value.isResearcher} : {}),
    ...(value.discordInfo ? {discordInfo: value.discordInfo} : {})
  };
}

export function purchaseDataToValue(data: any): PurchaseValue {
  return {
    userID: data.userID,
    amountCents: data.amountCents,
    startEpochTime: data.startEpochTime,
    finishEpochTime: data.finishEpochTime || 0,
    status: data.status || 'pending',
    message: data.message || ''
  };
}

export function purchaseValueToData(value: PurchaseValue): any {
  return {
    userID: value.userID,
    amountCents: value.amountCents,
    startEpochTime: value.startEpochTime,
    finishEpochTime: value.finishEpochTime,
    status: value.status,
    message: value.message
  };
}

export function conversationValueToData(value: Conversation): any {
  return {
    id: value.id,
    name: value.name,
    ownerID: value.ownerID,
    ...(value.participants ? {participants: value.participants} : {}),
    ...(value.participantIDs ? {participantIDs: value.participantIDs} : {}),
    visibility: value.visibility,
    creationEpochTime: value.creationEpochTime,
    isMultiTrack: value.isMultiTrack,
    compositeMediaUrl: value.compositeMediaUrl,
    ...(value.speakerMediaTrackUrls ? {speakerMediaTrackUrls: value.speakerMediaTrackUrls} : {}),
    transcribed: value.transcribed,
    transcriptID: value.transcriptID,
    transcriptionStatus: value.transcriptionStatus,
    ...(value.errorMessage ? {errorMessage: value.errorMessage}: {})
  };
}

export function conversationDataToValue(data: any, id: string): Conversation {
  return {
    id: id,
    name: data.name,
    ownerID: data.ownerID,
    ...(data.participants ? {participants: data.participants} : {}),
    ...(data.participantIDs ? {participantIDs: data.participantIDs} : {}),
    visibility: data.visibility,
    creationEpochTime: data.creationEpochTime,
    isMultiTrack: data.isMultiTrack,
    compositeMediaUrl: data.compositeMediaUrl,
    ...(data.speakerMediaTrackUrls ? {speakerMediaTrackUrls: data.speakerMediaTrackUrls} : {}),
    transcribed: data.transcribed,
    transcriptID: data.transcriptID,
    transcriptionStatus: data.transcriptionStatus,
    ...(data.errorMessage ? {errorMessage: data.errorMessage} : {})
  }
}