import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { User } from 'firebase/auth';
import { QueryDocumentSnapshot, DocumentData } from 'firebase/firestore';
import { DocumentSummary } from '../common/common_db';
import { FireLoomStore } from '../db';
import { getCurrentUser } from '../firebase_config';
import DeleteButton from '../components/DeleteButton';
import LoadingSpinner from '../components/LoadingSpinner';
import './Documents.css';

function Documents() {
  const navigate = useNavigate();
  const [docStore] = useState(new FireLoomStore());
  const [documents, setDocuments] = useState<null | DocumentSummary[]>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [startAfter, setStartAfter] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [deletingDocIds, setDeletingDocIds] = useState<Set<string>>(new Set());

  function fetchDocuments() {
    docStore.getDocumentSummaries(startAfter).then((docs) => {
      setDocuments(prevDocs => {
        const allDocs = [...(prevDocs || []), ...docs.summaries];
        return Array.from(
          new Map(allDocs.map(doc => [doc.id, doc]))
          .values()
        );
      });
      console.log('last:', docs.last);
      setStartAfter(docs.last);
    });
  }

  useEffect(() => {
    getCurrentUser().then((currUser) => {
      setUser(currUser);
      setLoading(false);
      fetchDocuments();
    })
  }, []);

  if (user == null && !loading) {
    return <Navigate to="/auth" />;
  }

  function handleCreateDocument() {
    docStore.createDocument("Untitled Document").then((summary) => {
      // setDocuments(docs => [summary, ...(docs || [])]);
      navigate(`/loom/${summary.id}`, {state: { startEditingTitle: true }});
    });
  }

  function handleDeleteDocument(docId: string) {
    // Immediately mark as deleting
    setDeletingDocIds(prev => new Set([...prev, docId]));
    
    docStore.deleteDocument(docId)
      .then(() => {
        setDeletingDocIds(prev => {
          const next = new Set(prev);
          next.delete(docId);
          return next;
        });
        setDocuments(prevDocs => {
          if (prevDocs == null) {
            return null;
          }
          return prevDocs.filter(doc => doc.id !== docId);
        });
        // setStartAfter(null);
        // fetchDocuments();
      })
      .catch(error => {
        // On error, remove from deleting state
        setDeletingDocIds(prev => {
          const next = new Set(prev);
          next.delete(docId);
          return next;
        });
        console.error('Failed to delete document:', error);
        // You might want to add error handling UI here
      });
  }

  function formatDate(timestamp: number) {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  return (
    <div className="documents-container">
      <div className="documents-header">
        <h2>Your Loom Documents</h2>
        <button 
          className="new-doc-button"
          onClick={handleCreateDocument}
        >
          + New Document
        </button>
      </div>

      {documents === null ? (
        <div className="loading-container">
          <p>Loading...</p>
        </div>
      ) : (
        <div className="documents-table-container">
          <table className="documents-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Created</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {documents.map((doc) => {
                const isDeleting = deletingDocIds.has(doc.id);
                return (
                  <tr 
                    key={doc.id}
                    className={isDeleting ? 'deleting' : ''}
                  >
                    <td>
                      <Link 
                        to={`/loom/${doc.id}`}
                        className="document-name"
                        onClick={e => isDeleting && e.preventDefault()}
                        style={isDeleting ? { pointerEvents: 'none', color: '#999' } : undefined}
                      >
                        {doc.name}
                      </Link>
                    </td>
                    <td className="document-date">
                      {formatDate(doc.creationEpochTime)}
                    </td>
                    <td className="document-actions">
                      {isDeleting ? (
                        <LoadingSpinner size="tiny" />
                      ) : (
                        <DeleteButton
                          onDelete={() => handleDeleteDocument(doc.id)}
                          itemType="document"
                          className="table-delete-button"
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {startAfter && (
            <div className="load-more-container">
              <button 
                className="load-more-button"
                onClick={fetchDocuments}
              >
                Load More
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Documents;