import React, { useState, useEffect, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { User } from 'firebase/auth';
import { getCurrentUser } from '../firebase_config';
import { FireLoomStore } from '../db'; 
import './Conversations.css';
import { Conversation, ConvoVisibility, TranscriptionStatus} from '../common/common_db';
import { callTranscribeMedia } from '../callables';
import LoadingSpinner from '../components/LoadingSpinner';
import DeleteButton from '../components/DeleteButton';
import { useConversations } from '../hooks/useConversations';



const VisibilityIcon: React.FC<{ visibility: ConvoVisibility }> = ({ visibility }) => {
  const getIcon = () => {
    switch (visibility) {
      case 'public':
        return <span title="Public">🌎</span>;
      case 'private':
        return <span title="Private">🔒</span>;
      case 'research':
        return <span title="Research">🔬</span>;
    }
  };
  
  return (
    <div className="visibility-indicator" title={`Visibility: ${visibility}`}>
      {getIcon()}
    </div>
  );
};

const TranscriptionStatusComponent: React.FC<{
  status: TranscriptionStatus;
  errorMessage?: string;
  onRetry?: () => void;
}> = ({ status, errorMessage, onRetry }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  switch (status) {
    case 'processing':
      return (
        <div className="status-badge processing">
          <LoadingSpinner size="small" />
          <span>Processing</span>
        </div>
      );
    case 'failed':
      return (
        <div className="status-badge failed">
          <span>⚠️ Transcription Pipeline Failed</span>
          {onRetry && (
            <button 
              onClick={(e) => {
                e.stopPropagation();
                onRetry();
              }}
            >
              Retry
            </button>
          )}
        </div>
      );
    case 'pending':
      return (
        <div className="status-badge processing">
          <LoadingSpinner size="small" />
          <span>Pending</span>
        </div>
      );
    case 'completed':
      return (
        <div className="status-badge completed">
          <span>✓ Completed</span>
        </div>
      );
    default:
      return null;
  }
};


interface ConversationListProps {
  conversations: Array<Conversation>;
  currentUserID: string;
  onDelete: (id: string) => void;
  onSelect: (id: string) => void;
  onRetryTranscription: (conversation: Conversation) => void;
}



export const ConversationList: React.FC<ConversationListProps> = ({
  conversations,
  currentUserID,
  onDelete,
  onSelect,
  onRetryTranscription
}) => {
  return (
    <table className="conversations-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Date</th>
          <th>Info</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {conversations.map((conversation) => (
          <tr 
            key={conversation.id}
          >
            <td
              className="conversation-name-cell"
              onClick={() => conversation.transcribed ? onSelect(conversation.id) : undefined}
              style={{ cursor: conversation.transcribed ? 'pointer' : 'default' }}>
                {conversation.name}
            </td>
            <td>{new Date(conversation.creationEpochTime).toLocaleDateString()}</td>
            <td>
              <div className="meta-info">
                <VisibilityIcon visibility={conversation.visibility} />
                {conversation.ownerID === currentUserID && (
                  <div className="owner-badge" title="You own this conversation">
                    👤
                  </div>
                )}
              </div>
            </td>
            <td>
              <TranscriptionStatusComponent 
                status={conversation.transcriptionStatus}
                errorMessage={conversation.errorMessage}
                onRetry={conversation.ownerID === currentUserID ? 
                  () => onRetryTranscription(conversation) : undefined}
              />
            </td>
            <td>
              {conversation.ownerID === currentUserID && conversation.transcribed && (
                <DeleteButton
                  onDelete={() => onDelete(conversation.id)}
                  itemType="conversation"
                  className="conversation-delete-btn"/>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

function Conversations() {
  const [docStore] = useState(new FireLoomStore());
  const [user, setUser] = useState<User | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  // const [mediaFormKey, setMediaFormKey] = useState(0);
  // const [transcriptFormKey, setTranscriptFormKey] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [mediaFile, setMediaFile] = useState<File | null>(null);
  // const [transcriptFile, setTranscriptFile] = useState<File | null>(null);
  // const [uploadMode, setUploadMode] = useState<'automatic' | 'manual'>('automatic');
  const navigate = useNavigate();

  const { conversations: convosFromHook, loading: conversationsLoading } = useConversations(docStore);

  useEffect(() => {
    getCurrentUser().then((currUser) => {
      setUser(currUser);
      if (currUser) {
        setUserId(currUser.uid);
      }
      setLoading(false);
    });
  }, []);

  if (user == null && !loading) {
    return <Navigate to="/auth" />;
  }

  // async function handleAutomaticUpload(e: React.FormEvent) {
  //   console.log("Automatic Upload")
  //   e.preventDefault();
  //   if (!mediaFile) {
  //     console.log("No media file")
  //     return;
  //   }
  //   setIsUploading(true);
  //   try {
  //     console.log("Creating conversation")
  //     const convo = await docStore.createConversation(mediaFile);
  //     console.log("Calling transcribe media")
  //     await callTranscribeMedia(convo);
  //   } catch (error) {
  //     console.error('Error uploading file:', error);
  //     // You might want to add some error handling UI here
  //   } finally {
  //     setMediaFile(null);
  //     setIsUploading(false);
  //     if (fileInputRef.current) {
  //       fileInputRef.current.value = '';
  //     }
  //   }
  // }

  async function handleUpload(file: File) {
    setIsUploading(true);
    try {
      // Create conversation first
      console.log("handling uplode, file=", file)
      const convo = await docStore.createConversation(file);
      console.log("conversation created, ", convo)
      setIsUploading(false);
      
      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
  
      // Call transcribe separately outside the try block
      await callTranscribeMedia(convo);
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }

  // async function handleManualUpload(e: React.FormEvent) {
  //   e.preventDefault();
  //   if (!mediaFile || !transcriptFile) {
  //     return;
  //   }
  //   setIsUploading(true);
  //   const convo = await docStore.createManuallyTranscribedConversation(mediaFile, transcriptFile);
  //   setMediaFile(null);
  //   setTranscriptFile(null);
  //   setIsUploading(false);
  //   (e.target as HTMLFormElement).reset();
  //   setMediaFormKey((key) => key + 1);
  //   setTranscriptFormKey((key) => key + 1);
  // }

  async function handleDeleteConversation(convoID: string) {
    await docStore.deleteConversation(convoID);
  }

  function handleSelectConversation(convoID: string) {
    console.log("CLICK")
    navigate(`/conversation/${convoID}`);
  }

  async function handleRetryTranscription(conversation: Conversation) {
    try {
      await docStore.updateConversation(conversation.id, {
        ...conversation,
        transcriptionStatus: 'pending',
        errorMessage: "",
      });
      await callTranscribeMedia(conversation);
    } catch (error) {
      console.error('Error retrying transcription:', error);
    }
  }

  return (
    <div className="conversations-container">
      <div className="conversations-header">
        <h1 className="conversations-title">Your Conversations</h1>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          {isUploading && <LoadingSpinner size="small" />}
          <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="audio/*,video/*"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              handleUpload(file);
            }
          }}
        />
          <button 
            className="add-conversation-button"
            onClick={() => fileInputRef.current?.click()}
            disabled={isUploading}
          >
            {isUploading ? 'Uploading...' : '+ Add New Conversation'}
          </button>
        </div>
      </div>
      
      {loading || conversationsLoading ? (
        <LoadingSpinner />
      ) : (
        <ConversationList
          conversations={convosFromHook || []}
          onDelete={handleDeleteConversation}
          onSelect={handleSelectConversation}
          onRetryTranscription={handleRetryTranscription}
          currentUserID={userId || ''}
        />
      )}
    </div>
  );
}

export default Conversations;
