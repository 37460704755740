
import React from 'react';
import { PageContent } from '../components/InfoPageContent';

function About() {
  return (
    <PageContent title="About" showBackgroundImage={false}>
      <p className='content-text'>We're developing a suite of AI tools to make genuine honesty, focus, and legibility in small-group discussions feel <i>inevitable</i> without coercion. Our work is informed by some of the social theories articulated <a href="https://naturalhazard.xyz/ben_jess_sarah_starter_pack">here</a>.</p>
      <p className='content-text'>Transcriptions are currently powered by whiserpX on <a href='https://replicate.com/victor-upmeet/whisperx'>replicate</a> and base model generations are powered by Llama405b on <a href='https://app.hyperbolic.xyz/models/llama31-405b'>hyperbolic</a>.</p>
      <p className='content-text'><a href="https://cyborgism.wiki/hypha/loom">Loom</a> refers to a type of interface to base models based on generating multiple completions and letting the user select them in a tree structure. This website has just one implementation of Loom; <a href="https://github.com/cosmicoptima/loom">Celeste's Obsidian Loom extension</a> is another implementation.</p>
      <p className='content-text'>Our current focus is making something usable for the internal research team, which means we're going to be experimenting frequently, and given that, we're making No Support Guarantees 😎. Tread carefully.</p>
      <p className='content-text'>Website maintined by <a href="https://morpheus.systems/">Morpheus Inc.</a></p>
  </PageContent>
  );
}

export default About;
