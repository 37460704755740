import React, { useState, useRef, useEffect, useCallback} from 'react';
import './Dropdown.css';

export interface DropdownOption<T = string> {
  label: string;
  value: T;
  disabled?: boolean;
}

export interface DropdownProps<T = string> {
  options: DropdownOption<T>[];
  value?: T;
  onChange: (value: T) => void;
  placeholder?: string;
  disabled?: boolean;
}

export const Dropdown = <T extends React.Key>({ 
  options = [], 
  value,
  onChange,
  placeholder = 'Select an option',
  disabled = false 
}: DropdownProps<T>): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<T | undefined>(value);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const positionDropdownMenu = useCallback((): void => {
    if (buttonRef.current && menuRef.current && isOpen) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      menuRef.current.style.width = `${buttonRect.width}px`;
      menuRef.current.style.left = `${buttonRect.left}px`;
      menuRef.current.style.top = `${buttonRect.bottom + 4}px`;
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      positionDropdownMenu();
      window.addEventListener('scroll', positionDropdownMenu, true);
      window.addEventListener('resize', positionDropdownMenu);
      
      return () => {
        window.removeEventListener('scroll', positionDropdownMenu, true);
        window.removeEventListener('resize', positionDropdownMenu);
      };
    }
  }, [isOpen, positionDropdownMenu]);

  const handleSelect = (option: DropdownOption<T>): void => {
    if (option.disabled) return;
    setSelectedOption(option.value);
    onChange(option.value);
    setIsOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>): void => {
    if (!isOpen && (event.key === 'Enter' || event.key === ' ')) {
      event.preventDefault();
      setIsOpen(true);
    }
    if (isOpen && event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const selectedLabel = options.find(opt => opt.value === selectedOption)?.label || placeholder;

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <button
        ref={buttonRef}
        type="button"
        className="dropdown-button"
        onClick={() => !disabled && setIsOpen(!isOpen)}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <span className={!selectedOption ? 'dropdown-placeholder' : ''}>
          {selectedLabel}
        </span>
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`} />
      </button>

      {isOpen && (
        <div 
          ref={menuRef}
          className="dropdown-menu"
          role="listbox"
          aria-label="Options"
        >
          {options.length > 0 ? (
            options.map((option) => (
              <div
                key={option.value}
                className={`dropdown-item ${
                  option.value === selectedOption ? 'selected' : ''
                } ${option.disabled ? 'disabled' : ''}`}
                onClick={() => handleSelect(option)}
                role="option"
                aria-selected={option.value === selectedOption}
                aria-disabled={option.disabled}
              >
                {option.label}
              </div>
            ))
          ) : (
            <div className="dropdown-no-options">No options available</div>
          )}
        </div>
      )}
    </div>
  );
};

