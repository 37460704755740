import React, { useState, useMemo, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { QueryDocumentSnapshot, DocumentData } from 'firebase/firestore';
import { UserValue } from '../common/common_db';
import { formatUD } from '../common/util';
import { getCurrentUser } from '../firebase_config';
import { FireLoomStore } from '../db';
import { callCreditsCheckoutSession, callGetOrCreateUser } from '../callables';
import { PurchaseValue } from '../common/common_db';
import './Credits.css';

// Initialize Stripe
const stripePromise = loadStripe('pk_live_51QANK0Rvq04dC3VjNkmiZkZfZ89E5Ew47WHE6tjvz4mRj2xtmcImDGAwTjCLxssb0YHY9oixdqqaWvnn0HxOXTPh00HroAOHRz');

const Credits = () => {
  const [customDollars, setCustomDollars] = useState(10);
  const [purchaseStatus, setPurchaseStatus] = useState('');
  const [userValue, setUserValue] = useState<UserValue | null>(null);
  const [purchaseValues, setPurchaseValues] = useState<PurchaseValue[] | null>(null);
  const [startAfter, setStartAfter] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const fireStore = useMemo(() => new FireLoomStore(), []);

  useEffect(() => {
    async function doSetUser() {
      const user = await callGetOrCreateUser();
      if (user) {
        setUserValue(user);
      }
    }
    doSetUser();
  }, []);

  function fetchPurchases() {
    fireStore.getPurchases(startAfter).then((purchases) => {
      const newPurchases = purchases.purchases;
      const allPurchases = [...(purchaseValues || []), ...newPurchases];
      
      const seen = new Set();
      const uniquePurchases = allPurchases.filter(purchase => {
        const key = `${purchase.startEpochTime}-${purchase.amountCents}-${purchase.status}`;
        if (seen.has(key)) return false;
        seen.add(key);
        return true;
      });
      
      setPurchaseValues(uniquePurchases);
      setStartAfter(purchases.last);
    });
  }

  useEffect(() => {
    fetchPurchases();
  }, []);

  async function handlePurchase(cents: number) {
    const stripe = await stripePromise;
    if (stripe == null) {
      setPurchaseStatus('Error loading Stripe.js');
      return;
    }

    try {
      const user = await getCurrentUser();
      if (!user) {
        setPurchaseStatus('Please sign in to make a purchase');
        return;
      }

      const { sessionId } = await callCreditsCheckoutSession({
        cents,
        successUrl: window.location.origin + '/credits',
        cancelUrl: window.location.origin + '/credits',
      });

      if (!sessionId) {
        setPurchaseStatus('Error creating checkout session');
        return;
      }

      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        setPurchaseStatus(`Error redirecting to checkout: ${error.message}`);
      }
    } catch (error) {
      setPurchaseStatus('Error processing purchase');
      console.error('Purchase error:', error);
    }
  }

  const handleCustomAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.min(200, Math.max(10, Number(e.target.value)));
    setCustomDollars(value);
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return { bg: '#dcfce7', text: '#166534' };
      case 'pending':
        return { bg: '#fef9c3', text: '#854d0e' };
      default:
        return { bg: '#f3f4f6', text: '#4b5563' };
    }
  };

  return (
    <div className="credits-container">
      {/* Credits Display */}
      <div className="credits-display">
        <div className="credits-label">Your Credits</div>
        <div className="credits-amount">
          {userValue ? formatUD(userValue.creditsUD) : '...'}
        </div>
      </div>

      {/* Purchase History */}
      {purchaseValues && purchaseValues.length > 0 && (
        <div className="purchase-history">
          <h2 className="history-title">Purchase History</h2>
          <div className="table-container">
            <table className="history-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {purchaseValues.map((purchase, index) => {
                  const statusColor = getStatusColor(purchase.status);
                  return (
                    <tr key={index}>
                      <td>{new Date(purchase.startEpochTime).toLocaleString()}</td>
                      <td className="amount-cell">${(purchase.amountCents / 100).toFixed(2)}</td>
                      <td>
                        <span className="status-badge" style={{
                          backgroundColor: statusColor.bg,
                          color: statusColor.text
                        }}>
                          {purchase.status}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {startAfter && (
            <div className="load-more-container">
              <button onClick={fetchPurchases} className="load-more-button">
                Load more
              </button>
            </div>
          )}
        </div>
      )}

      {/* Purchase Interface */}
      <div className="purchase-section">
        <div className="purchase-container">
          <div className="amount-input-container">
            <div className="input-wrapper">
              <input
                type="number"
                value={customDollars}
                onChange={handleCustomAmountChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                min="10"
                max="200"
                className={`amount-input ${isFocused ? 'focused' : ''}`}
              />
              <span className="currency-symbol">$</span>
            </div>

            <div className="credits-preview">
              You'll receive {formatUD(1000 * 1000 * customDollars)} credits
            </div>

            <div className="amount-limits">
              Min: $10 • Max: $200
            </div>
          </div>

          <button
            onClick={() => handlePurchase(Math.round(100 * customDollars))}
            className="purchase-button"
          >
            Purchase Credits
          </button>

          {purchaseStatus && (
            <div className="error-message">{purchaseStatus}</div>
          )}
        </div>
      </div>

      {/* Alpha software notice */}
      <div className="alpha-notice">
        NOTE: This is alpha software. Service is provided as-is. 
        No guaranteed refund policy for credits. 
        Contact jessica@morpheus.systems with questions.
      </div>
    </div>
  );
};

export default Credits;