
import React, { useState, useEffect } from 'react';
import {
  signInWithEmailAndPassword, createUserWithEmailAndPassword,
  signOut, onAuthStateChanged, User,
  GoogleAuthProvider, signInWithPopup,
} from 'firebase/auth';
import { UserValue } from '../common/common_db';
import { firebaseAuth } from '../firebase_config';
import { DiscordButton } from '../components/DiscordButton';
import { useFireLoomStore } from '../hooks';
import { doc, onSnapshot } from 'firebase/firestore';
import { callGetOrCreateUser } from '../callables';

const isEmailEnabled = false;
const provider = new GoogleAuthProvider();

function Auth() {
  const [user, setUser] = useState<User | null>(null);
  const [userInfo, setUserInfo] = useState<UserValue | null>(null);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const fireLoom = useFireLoomStore();

  const handleGoogleSignIn = (e: React.FormEvent) => {
    e.preventDefault();
    async function go() {
      setIsSigningIn(true);
      try {
        const result = await signInWithPopup(firebaseAuth, provider);
        setUser(result.user);
      } catch (error: any) {
        console.error("Error during sign-in: ", error.message);
      } finally {
        console.log('finally of create user, calling getOrCreateUser')
        callGetOrCreateUser();
        console.log('called getOrCreateUser')
        setIsSigningIn(false);
      }
    }
    go();
  };

  // Handle sign-in with email and password
  const handleEmailSignIn = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSigningIn(true);
    
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then((result) => {
        setUser(result.user); // Set the logged-in user
      })
      .catch((error) => {
        console.error("Error during sign-in: ", error.message);
      })
      .finally(() => {
        console.log('finally of create user, calling getOrCreateUser')
        callGetOrCreateUser();
        console.log('called getOrCreateUser')
        setIsSigningIn(false);
      });
  };

  // Handle user registration (create account)
  const handleRegister = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSigningIn(true);
    console.log('calling createUserWithEmailAndPassword')
    createUserWithEmailAndPassword(firebaseAuth, email, password)
      .then((result) => {
        console.log('in "then" of createUserWithEmailAndPassword')
        setUser(result.user); // Set the logged-in user
      })
      .catch((error) => {
        console.error("Error during registration: ", error.message);
      })
      .finally(() => {
        console.log('finally of create user, calling getOrCreateUser')
        callGetOrCreateUser();
        console.log('called getOrCreateUser')
        setIsSigningIn(false);
      });
  };

  // Handle sign-out
  const handleSignOut = () => {
    signOut(firebaseAuth)
      .then(() => {
        setUser(null); // Reset the user state to null
      })
      .catch((error) => {
        console.error("Error during sign-out: ", error);
      });
  };

  // Listen for changes in the auth state and user data
  useEffect(() => {
    let unsubscribeUserData: (() => void) | undefined;

    const unsubscribeAuth = onAuthStateChanged(firebaseAuth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        
        // Set up real-time listener for user data
        const userDocRef = doc(fireLoom.usersCollectionRef, currentUser.uid);
        unsubscribeUserData = onSnapshot(userDocRef, 
          (doc) => {
            if (doc.exists()) {
              setUserInfo(doc.data() as UserValue);
            } else {
              setUserInfo(null);
            }
          },
          (error) => {
            console.error("Error listening to user data:", error);
          }
        );
      } else {
        setUser(null);
        setUserInfo(null);
        
        // Cleanup user data subscription when signed out
        if (unsubscribeUserData) {
          unsubscribeUserData();
          unsubscribeUserData = undefined;
        }
      }
    });

    // Cleanup subscriptions on unmount
    return () => {
      unsubscribeAuth();
      if (unsubscribeUserData) {
        unsubscribeUserData();
      }
    };
  }, [fireLoom.usersCollectionRef]);

  return (
    <div>
      <h1 className="title">Authentication</h1>

      {user ? (
        <div style={{textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center"}}>
          <p>Welcome, {user.email}</p>
          <DiscordButton discordInfo={userInfo?.discordInfo} userID={user.uid}/>
          <button className="round-button" onClick={handleSignOut}>Log Out</button>
        </div>
      ) : (
        <div style={{textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center"}}>
          {isEmailEnabled && (
            <div>
              <form onSubmit={isRegistering ? handleRegister : handleEmailSignIn}>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button className="round-button" type="submit" disabled={isSigningIn}>
                  {isSigningIn ? "Signing in..." : isRegistering ? "Register" : "Sign In"}
                </button>
              </form>
              <button className="round-button" onClick={() => setIsRegistering(!isRegistering)}>
                {isRegistering ? "Already have an account? Sign in" : "Create an account"}
              </button>
            </div>
            )}
          <button className="round-button" onClick={handleGoogleSignIn} disabled={isSigningIn}>Sign in with Google</button>
        </div>
      )}
    </div>
  );
}

export default Auth;
